import type { FormInstance } from "antd";
import { useState } from "react";
import { useRecoilState } from "recoil";

import { useAsyncSelector } from "@hooks/useAsyncSelector";
import type FinancialPositionDto from "smavesto.core/lib/types/dto/onboarding/FinancialPositionDto";

import { useApiErrorHandler } from "@src/hooks/error-handling/useApiErrorHandler";
import { useIsUnderageMode } from "@page-components/onboarding/hooks";
import isApiErrorResponse from "smavesto.core/lib/utils/typeguards/isApiErrorResponse";
import { userUriState } from "@states/auth.state";
import {
  financialPositionState,
  useRefreshFinancialPosition
} from "@states/financial-position.state";
import {
  Actions,
  strategyRiskErrorsState,
  strategyRiskReducer,
  strategyRiskState
} from "@states/strategy-risk.state";
import smavestoCore from "@src/utils/services/SmavestoCoreClient";

export const useOnFinishRiskModalForm = (
  parentForm: FormInstance,
  destroyModal: () => void
) => {
  const [handleApiError] = useApiErrorHandler();

  const isUnderageMode = useIsUnderageMode();

  const appMode = isUnderageMode ? "activeOnboardingOwner" : "loggedInUser";

  const [, userUri] = useAsyncSelector(userUriState(appMode));

  const [, financialPosition] = useAsyncSelector(
    financialPositionState(appMode)
  );

  const refreshFinancialPosition = useRefreshFinancialPosition(appMode);

  const [{ riskDBValue, strategyDBValue, strategy }, setStrategyRiskState] =
    useRecoilState(strategyRiskState);

  const [strategyRiskErrors, setErrors] = useRecoilState(
    strategyRiskErrorsState
  );

  const [isSubmit, setIsSubmit] = useState(false);

  const { apiError, ...restErrorMessages } = strategyRiskErrors;

  const errorList = [
    ...Object.values(restErrorMessages).flatMap(errObj =>
      Object.values(errObj)
    ),
    apiError
  ].filter(e => !!e) as string[];

  const onFinish = async ({
    investmentRisk,
    strategy
  }: {
    investmentRisk: number;
    strategy: FinancialPositionDto["strategy"];
  }) => {
    setIsSubmit(true);

    if (!strategy || !financialPosition || !userUri) return;

    if (strategy === financialPosition.strategy) {
      setIsSubmit(false);
      parentForm.setFieldsValue({ investmentRisk });
      destroyModal();
      return;
    }

    const body: FinancialPositionDto = { ...financialPosition, strategy };

    const setFinancialSituationResponse =
      await smavestoCore.services.user.setFinancialSituation(userUri, body);

    if (isApiErrorResponse(setFinancialSituationResponse)) {
      handleApiError(setFinancialSituationResponse, "modal", "error");
      setIsSubmit(false);
      return;
    }

    refreshFinancialPosition();
    parentForm.setFieldsValue({ investmentRisk });

    setIsSubmit(false);
    destroyModal();
  };

  const onCancel = async () => {
    destroyModal();
    parentForm.setFieldsValue({ investmentRisk: riskDBValue });
    !!errorList.length &&
      setErrors({ strategy: { range: "" }, risk: { range: "" }, apiError: "" });

    if (financialPosition && strategyDBValue !== strategy && userUri) {
      const body: FinancialPositionDto = {
        ...financialPosition,
        strategy: strategyDBValue ?? "MEDIUM_RISK"
      };

      const setFinancialSituationResponse =
        await smavestoCore.services.user.setFinancialSituation(userUri, body);

      if (isApiErrorResponse(setFinancialSituationResponse)) {
        handleApiError(setFinancialSituationResponse, "modal", "error");
        setIsSubmit(false);
        return;
      }

      refreshFinancialPosition();
    }

    setStrategyRiskState(strategyRiskReducer(Actions.reset()));
  };

  return {
    errorList,
    isSubmit,
    onCancel,
    onFinish
  };
};
