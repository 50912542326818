import type { FormInstance } from "antd";
import { message } from "antd";
import { useRecoilState, useSetRecoilState } from "recoil";

import useOnboardingLabels from "@hooks/onboarding/labels/useOnboardingLabels";
import { useAsyncSelector } from "@hooks/useAsyncSelector";
import { RiskValidateModalContent } from "@page-components/onboarding/FinancialPosition/strategy/RiskValidateModalContent";
import { isValidRiskToStrategy } from "@page-components/onboarding/FinancialPosition/strategy/bus";
import {
  parseRiskNumValueToLabel,
  parseStrategyNumValueToLabel
} from "@page-components/onboarding/FinancialPosition/strategy/helpers";
import smavestoCore from "@src/utils/services/SmavestoCoreClient";
import { userUriState } from "@states/auth.state";
import {
  Actions,
  strategyRiskErrorsState,
  strategyRiskReducer,
  strategyRiskState
} from "@states/strategy-risk.state";
import useThemedModal from "@theme/hook/useThemedModal";

import isApiErrorResponse from "smavesto.core/lib/utils/typeguards/isApiErrorResponse";

import { useIsUnderageMode } from "../../hooks";
import { RiskModalForm } from "./RiskModalForm";
import { updateRiskAndStrategyErrorsState } from "./investmentRiskValidators";

export const useInvestmentRisk = (form: FormInstance) => {
  const { contextHolder, openModal } = useThemedModal();

  const [, userUri] = useAsyncSelector(userUriState("activeOnboardingOwner"));

  const [{ risk, riskDBValue, strategy }, setStrategyRiskState] =
    useRecoilState(strategyRiskState);

  const labels = useOnboardingLabels();

  const isUnderageMode = useIsUnderageMode();

  const setErrors = useSetRecoilState(strategyRiskErrorsState);

  const onSliderAfterChange = async (value: number) => {
    setStrategyRiskState(strategyRiskReducer(Actions.changeRisk(value)));

    const isValid = isValidRiskToStrategy(strategy ?? "HIGH_RISK", value);

    const riskLabel = parseRiskNumValueToLabel(value);
    const strategyLabel = parseStrategyNumValueToLabel(strategy || "HIGH_RISK");

    updateRiskAndStrategyErrorsState(
      isValid,
      setErrors,
      `Das Anlagerisiko '${riskLabel}' ist nicht mit dem Anlageziel '${strategyLabel}' kompatibel.`,
      `Das Anlageziel '${strategyLabel}' ist nicht mit dem Anlagerisiko '${riskLabel}' kompatibel.`
    );

    const validationResult = await smavestoCore.services.validation.validate(
      "validate-risk",
      `risk=${value}`,
      userUri
    );

    if (!userUri && value < 7) {
      openModal("info", {
        ...labels.investmentSettings.warningModalSettings.investmentRisk,
        content: <RiskValidateModalContent isUnderage={isUnderageMode} />
      });
      form.setFieldsValue({ investmentRisk: riskDBValue ?? 7 });
    } else if (userUri && isApiErrorResponse(validationResult)) {
      if (validationResult.key === "validation.risk.min") {
        openModal("info", {
          ...labels.investmentSettings.warningModalSettings.investmentRisk,
          content: <RiskValidateModalContent isUnderage={isUnderageMode} />
        });
        form.setFieldsValue({ investmentRisk: riskDBValue ?? 7 });
        return;
      }

      if (strategy) {
        const inst = openModal("confirm", {
          ...labels.investmentSettings.warningModalSettings.strategy,
          modalRender: () => (
            <RiskModalForm
              parentForm={form}
              destroyModal={() => inst.destroy()}
            />
          )
        });

        return;
      }

      message.error(
        `Fehler bei der Validierung: ${validationResult.humanReadableError}`,
        10
      );
      setErrors(prev => ({
        ...prev,
        apiError: `Fehler bei der Validierung: ${validationResult.humanReadableError}`
      }));
    }
  };

  return {
    risk,
    onSliderAfterChange,
    contextHolder
  };
};
